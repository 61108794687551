
import Vue from 'vue';
import { mask } from 'vue-the-mask';
import { Validation, validationMixin } from 'vuelidate';
import { or, required, requiredIf } from 'vuelidate/lib/validators';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { Stable } from '@/store/modules/stable';
import { showError } from '@/helpers/utils';
import FormDialog from '@/components/FormDialog.vue';
import _ from 'lodash';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import { cellphone, phone } from '@/helpers/validations';
import { ValidationGroups } from 'vue/types/vue';

interface Data {
  creating: boolean;
  stableEdit: Stable;
  displayPhone: string;
}

interface Computed {
  phoneError: TranslateResult[];
}

interface Methods {
  update(): void;
  touchAddressFields(): void;
  cancel(): void;
}

interface Props {
  stable: Stable;
  dialog: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'StableCreate',
  mixins: [validationMixin, vuelidateMixin],
  components: { FormDialog },
  directives: { mask },
  validations: {
    stableEdit: {
      name: { required },
      address: {
        line1: {
          required: requiredIf(function (vm) {
            return !!(
              vm.city ||
              vm.postCode ||
              vm.state ||
              vm.line2 ||
              vm.country
            );
          })
        },
        state: {
          required: requiredIf(function (vm) {
            return !!(
              vm.city ||
              vm.postCode ||
              vm.line1 ||
              vm.line2 ||
              vm.country
            );
          })
        },
        city: {
          required: requiredIf(function (vm) {
            return !!(
              vm.state ||
              vm.postCode ||
              vm.line1 ||
              vm.line2 ||
              vm.country
            );
          })
        },
        country: {
          required: requiredIf(function (vm) {
            return !!(
              vm.state ||
              vm.postCode ||
              vm.line1 ||
              vm.line2 ||
              vm.city
            );
          })
        },
        postCode: {
          required: requiredIf(function (vm) {
            return !!(
              vm.state ||
              vm.city ||
              vm.line1 ||
              vm.line2 ||
              vm.country
            );
          })
        }
      }
    },
    displayPhone: { validFormat: or(phone, cellphone) }
  },
  data: () => ({
    creating: false,
    stableEdit: {
      id: '',
      name: '',
      horses: [],
      address: {
        line1: '',
        line2: '',
        postCode: '',
        city: '',
        state: '',
        country: ''
      },
      phone: ''
    },
    displayPhone: ''
  }),
  async created() {
    this.stableEdit = _.cloneDeep(this.stable);
    if (this.stableEdit.phone) {
      this.displayPhone = this.stableEdit.phone.slice(3);
    }
  },
  methods: {
    update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.creating = true;
        const stableNew = Object.assign({}, this.stableEdit);
        if (
          !stableNew.address?.state &&
          !stableNew.address?.city &&
          !stableNew.address?.postCode &&
          !stableNew.address?.line1 &&
          !stableNew.address?.line2 &&
          !stableNew.address?.country
        ) {
          delete stableNew.address;
        } else {
          stableNew.address = Object.assign({}, this.stableEdit.address);
        }
        if (this.displayPhone) {
          stableNew.phone = '+55' + this.displayPhone.replace(/[^.0-9]/g, '');
        } else {
          stableNew.phone = '';
        }
        this.$store
          .dispatch('StableModule/updateStable', {
            stable: stableNew
          })
          .then(() => {
            this.creating = false;
            this.$emit('stable-saved');
            this.$v.stableEdit.$reset();
          })
          .catch((error) => {
            showError(error);
            this.creating = false;
          });
      }
    },
    touchAddressFields() {
      this.$v.stableEdit.address?.$touch();
    },
    cancel() {
      this.stableEdit = _.cloneDeep(this.stable);
      this.$emit('cancel');
    }
  },
  computed: {
    phoneError() {
      const errors: TranslateResult[] = [];
      const field = this.$v.displayPhone as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      !field.validFormat &&
        errors.push(this.$t('stableCreateFlow.errorMessages.phoneIsInvalid'));
      return errors;
    }
  },
  props: {
    stable: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    }
  }
});
