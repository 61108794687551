
import HorseCard from "@/components/horse/HorseCard.vue";
import FeedCard from "@/components/FeedCard.vue";
import Calendar from "@/components/Calendar.vue";
import { CALENDAR_VIEW_TYPES, MODULE_TYPES, ModulesTypes } from "@/store/constants";
import Vue from "vue";
import { FeedItem } from "@/store/modules/feed";
import { Stable } from "@/store/modules/stable";
import NewCard from "@/components/NewCard.vue";
import HorseCreate from "@/components/horse/HorseCreate.vue";
import StableEdit from "@/components/stable/StableEdit.vue";
import { ERROR_LIST } from "@/helpers/errors";
import { mapState } from "vuex";
import Professional from "@/components/stable/Professional.vue";
import AwesomePhonenumber from "awesome-phonenumber";
import { Space } from "@/store/modules/space";
import { User } from "@/store/modules/user";
import PlaceHolderNewFeature from "@/components/PlaceHolderNewFeature.vue";
import { shortStringDots } from "@/helpers/utils";
import { TYPE } from "@/store/modules/notification";
import { SPACES_HOME } from "@/router";
import { mdiDotsVertical, mdiMapMarker, mdiPhone, mdiWhatsapp } from "@mdi/js";

interface Data {
  stable: Stable | null;
  stableEditable: Stable | null;
  calendarInitialView: string;
  feedItems: FeedItem[] | null | undefined;
  editMenu: boolean;
  editDialog: boolean;
  newHorseDialog: boolean;
}

interface Method {
  stableName(n: number): string;

  loadStable(): void;

  horseCreated(): void;

  open(): void;

  stableSaved(): void;

  closeEditStableDialog(): void;
}

interface Computed {
  mdiDotsVertical: string;
  mdiMapMarker: string;
  mdiPhone: string;
  mdiWhatsapp: string;
  types: ModulesTypes;
  addressFirstPart: string | null;
  addressSecondPart: string | null;
  addressThirdPart: string | null;
  phoneNumber: string | null;
  user: User;
  currentSpace: Space;
}

export default Vue.extend<Data, Method, Computed, Record<string, never>>({
  name: 'Stable',
  data: () => ({
    editMenu: false,
    editDialog: false,
    newHorseDialog: false,
    stable: null,
    stableEditable: null,
    calendarInitialView: CALENDAR_VIEW_TYPES.MONTH,
    feedItems: undefined,
    calendarEvents: [
      {
        name: 'Visita veterinaria',
        moduleName: MODULE_TYPES.HEALTH.name,
        color: MODULE_TYPES.HEALTH.color,
        icon: MODULE_TYPES.HEALTH.icon,
        start: new Date(),
        details:
          'Os seguintes cavalos tem consulta:<ul>\n' +
          '  <li>Carlinha</li>\n' +
          '  <li>Olga</li>\n' +
          '  <li>Ambigue</li>\n' +
          '</ul>',
        timed: true
      }
      // {
      //   name: 'Vermifugação',
      //   moduleName: MODULE_TYPES.HEALTH.name,
      //   color: MODULE_TYPES.HEALTH.color,
      //   icon: MODULE_TYPES.HEALTH.icon,
      //   start: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      //   end: new Date(),
      //   details: 'Tiago vai efetuar a vermifugação de todos os cavalos',
      //   timed: true
      // },
      // {
      //   name: 'Trocar a ferradura do Pé de Pano',
      //   moduleName: MODULE_TYPES.HORSESHOE.name,
      //   color: MODULE_TYPES.HORSESHOE.color,
      //   icon: MODULE_TYPES.HORSESHOE.icon,
      //   start: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      //   details: 'O ferrador Mario vai colocar ferraduras para competição',
      //   timed: true
      // },
      // {
      //   name: 'Pé de Pano ira saltar',
      //   moduleName: MODULE_TYPES.SPORT.name,
      //   color: MODULE_TYPES.SPORT.color,
      //   icon: MODULE_TYPES.SPORT.icon,
      //   start: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      //   details: 'Competição de salto no cavaleiro',
      //   timed: true
      // },
      // {
      //   name: 'Corrida do Pé de Pano',
      //   moduleName: MODULE_TYPES.SPORT.name,
      //   color: MODULE_TYPES.SPORT.color,
      //   icon: MODULE_TYPES.SPORT.icon,
      //   start: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
      //   details: 'Corrida no cavaleiro',
      //   timed: true
      // },
      // {
      //   name: 'Corrida do Pegasus',
      //   moduleName: MODULE_TYPES.SPORT.name,
      //   color: MODULE_TYPES.SPORT.color,
      //   icon: MODULE_TYPES.SPORT.icon,
      //   start: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      //   details: 'Corrida no cavaleiro',
      //   timed: true
      // },
      // {
      //   name: 'Corrida do Spirit',
      //   moduleName: MODULE_TYPES.SPORT.name,
      //   color: MODULE_TYPES.SPORT.color,
      //   icon: MODULE_TYPES.SPORT.icon,
      //   start: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      //   details: 'Corrida no cavaleiro',
      //   timed: true
      // },
      // {
      //   name: 'Trocar a ferradura do Pesadelo',
      //   moduleName: MODULE_TYPES.HORSESHOE.name,
      //   color: MODULE_TYPES.HORSESHOE.color,
      //   icon: MODULE_TYPES.HORSESHOE.icon,
      //   start: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000),
      //   details: 'O ferrador Mario vai colocar ferraduras para competição',
      //   timed: true
      // },
      // {
      //   name: 'Visita veterinaria',
      //   moduleName: MODULE_TYPES.HORSESHOE.name,
      //   color: MODULE_TYPES.HORSESHOE.color,
      //   icon: MODULE_TYPES.HORSESHOE.icon,
      //   start: new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000),
      //   details: 'Tiago vai fazer um checkup dos animais que irao competir',
      //   timed: true
      // },
      // {
      //   name: 'Alimentacao para competicao',
      //   moduleName: MODULE_TYPES.FOOD.name,
      //   color: MODULE_TYPES.FOOD.color,
      //   icon: MODULE_TYPES.FOOD.icon,
      //   start: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      //   end: new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000),
      //   details: 'Suplementacao para competicao',
      //   timed: true
      // }
    ]
  }),
  components: {
    Professional,
    HorseCard,
    FeedCard,
    Calendar,
    NewCard,
    HorseCreate,
    StableEdit,
    PlaceHolderNewFeature
  },
  async created() {
    this.loadStable();
    this.stableEditable = Object.assign({}, this.stable, {
      address: Object.assign({}, this.stable?.address)
    });
  },
  methods: {
    stableName(n) {
      if (this.stable) {
        return shortStringDots(this.stable.name, n);
      }
      return '';
    },
    loadStable() {
      const stableId = this.$router.currentRoute.params.stableId;
      this.stable = this.$store.getters['StableModule/getById'](stableId);
      if (!this.stable) {
        const spaceId = this.$router.currentRoute.params.spaceId;
        this.$router.push({
          name: SPACES_HOME,
          params: { spaceId: spaceId },
          query: { error: ERROR_LIST.STABLE_NO_EXISTS.code.toString() }
        });
      } else {
        if (this.stable.address === undefined) {
          this.stable.address = {
            line1: '',
            line2: '',
            postCode: '',
            city: '',
            state: '',
            country: ''
          };
        }
      }
    },
    horseCreated() {
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('stable.create-horse.success.message'),
        type: TYPE.SUCCESS
      });
      this.newHorseDialog = false;
    },
    stableSaved() {
      this.loadStable();
      this.$store.commit('NotificationModule/add', {
        msg: this.$t('stable.edit.success.message'),
        type: TYPE.SUCCESS
      });
      this.editDialog = false;
    },
    open() {
      this.editMenu = false;
      this.editDialog = true;
    },
    closeEditStableDialog() {
      this.stableEditable = Object.assign({}, this.stable, {
        address: Object.assign({}, this.stable?.address)
      });
      this.editDialog = false;
    }
  },
  computed: {
    ...mapState('UserModule', ['user', 'currentSpace']),
    mdiDotsVertical() {
      return mdiDotsVertical;
    },
    mdiMapMarker() {
      return mdiMapMarker;
    },
    mdiPhone() {
      return mdiPhone;
    },
    mdiWhatsapp() {
      return mdiWhatsapp;
    },
    types() {
      return MODULE_TYPES;
    },
    addressFirstPart() {
      if (
        this.stable?.address &&
        (this.stable?.address?.line1 || this.stable?.address?.line2)
      ) {
        if (
          this.stable?.address &&
          this.stable?.address?.line1 &&
          this.stable?.address?.line2
        ) {
          return (
            this.stable?.address?.line1 + ', ' + this.stable?.address?.line2
          );
        } else {
          return this.stable?.address?.line1;
        }
      } else {
        return null;
      }
    },
    addressSecondPart() {
      if (
        this.stable?.address &&
        this.stable?.address?.city &&
        this.stable?.address?.state
      ) {
        return this.stable?.address?.city + ', ' + this.stable?.address?.state;
      } else {
        return null;
      }
    },
    addressThirdPart() {
      if (
        this.stable?.address &&
        this.stable?.address?.postCode &&
        this.stable?.address?.country
      ) {
        return (
          this.stable?.address?.postCode + ' - ' + this.stable?.address?.country
        );
      } else {
        return null;
      }
    },
    phoneNumber() {
      if (this.stable && this.stable?.phone) {
        return new AwesomePhonenumber(this.stable?.phone).getNumber('national');
      } else return null;
    }
  },
  watch: {
    stable(newValue) {
      if (newValue) {
        this.$store
          .dispatch('FeedModule/loadStableFeed', newValue.id)
          .then((feed) => {
            this.feedItems = feed;
          })
          .catch(() => {
            this.feedItems = null;
          });
      }
    }
  }
});
