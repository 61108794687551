
import ContactCard from '@/components/stable/ContactCard.vue';
import Vue from 'vue';
import { Professional } from '@/store/modules/stable';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import { mdiAlienOutline, mdiHorse, mdiInformation } from '@mdi/js';

interface Props {
  professional: Professional;
}

interface Computed {
  type: ModuleItem;
  mdiInformation: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'Professional',
  components: { ContactCard },
  props: {
    professional: { type: Object, required: true }
  },
  computed: {
    mdiInformation() {
      return mdiInformation;
    },
    type() {
      switch (this.$props.professional.type) {
        case 'veterinary':
          return MODULE_TYPES.HEALTH;
        case 'keeper':
          return MODULE_TYPES.FOOD;
        case 'blacksmith':
          return MODULE_TYPES.HORSESHOE;
        case 'rider':
          return MODULE_TYPES.SPORT;
        case 'horse-breaker':
          //TODO there is no module for that, for now we keep here but maybe should have a global solution?
          return {
            color: '#8D6E63',
            icon: mdiHorse,
            name: '',
            i18nCode: ''
          };
      }
      return {
        color: '#4CF000',
        icon: mdiAlienOutline,
        name: '',
        i18nCode: ''
      };
    }
  }
});
