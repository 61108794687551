
import Vue from 'vue';
import { Professional } from '@/store/modules/stable';
import {
  mdiDotsVertical,
  mdiEmail,
  mdiMapMarker,
  mdiPencil,
  mdiPhone
} from '@mdi/js';

interface Props {
  professional: Professional;
}

interface Computed {
  addressFirstPart: string | null;
  addressSecondPart: string | null;
  mdiPhone: string;
  mdiPencil: string;
  mdiDotsVertical: string;
  mdiMapMarker: string;
  mdiEmail: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'ContactCard',
  props: {
    professional: { type: Object, required: true }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    mdiPencil() {
      return mdiPencil;
    },
    mdiDotsVertical() {
      return mdiDotsVertical;
    },
    mdiMapMarker() {
      return mdiMapMarker;
    },
    mdiEmail() {
      return mdiEmail;
    },
    addressFirstPart() {
      if (this.$props.professional.address) {
        return (
          this.$props.professional.address.line1 +
          ',' +
          this.$props.professional.address.line2
        );
      } else {
        return null;
      }
    },
    addressSecondPart() {
      if (this.$props.professional.address) {
        return (
          this.$props.professional.address.city +
          ', ' +
          this.$props.professional.address.state +
          ' - ' +
          this.$props.professional.address.postCode +
          ' - ' +
          this.$props.professional.address.country
        );
      } else {
        return null;
      }
    }
  }
});
